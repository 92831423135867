import React from "react";
import classNames from "classnames";
import { Container, Text, Button } from "@atoms";
import { AppLink } from "@base";

const CtaGrid = ({ ctas }) => {
  return (
    <Container>
      {!!ctas?.length && (
        <div className="flex flex-wrap gap-5">
          {ctas?.map((cta, i) => {
            const { uid, color, icon, heading, descriptor, button } = cta;

            const background = {
              white: "bg-white",
              offWhite: "bg-offwhite",
              lighterTeal: "bg-teal-lighter",
              lightTeal: "bg-teal-light",
              teal: "bg-teal",
              darkTeal: "bg-teal-dark",
              black: "bg-black",
              pink: "bg-pink",
              purple: "bg-purple",
            }[color];

            const hoverBg = {
              white: "hover:bg-offwhite",
              offwhite: "hover:bg-teal-lighter",
              lighterTeal: "hover:bg-teal-light",
              lightTeal: "hover:bg-teal",
              teal: "hover:bg-teal-dark",
              darkTeal: "hover:bg-teal-black",
              black: "hover:bg-teal-dark",
              pink: "hover:bg-purple",
              purple: "hover:bg-pink",
            }[color];

            const isDark =
              color === "pink" ||
              color === "purple" ||
              color === "teal" ||
              color === "darkTeal" ||
              color === "black";

            return (
              <AppLink
                key={uid}
                to={button?.url}
                className={classNames(
                  "flex-grow rounded-lg py-12 px-12 shadow-lg duration-500",
                  {
                    "md:flex-[0_1_calc(50%-.625rem)] lg:px-16":
                      ctas.length % 2 !== 0 && i % 3 !== 2,
                    "md:px-16 lg:px-24": ctas.length % 2 !== 0 && i % 3 === 2,
                  },
                  background,
                  hoverBg
                )}
              >
                <div className="flex flex-col items-center gap-8 text-center">
                  {!!icon[0]?.code && (
                    <div
                      className={classNames(
                        "svg-icon flex h-auto w-16 items-center justify-center",
                        {
                          "md:w-28": ctas.length % 2 !== 0 && i % 3 === 2,
                          "md:w-12": ctas.length % 2 !== 0 && i % 3 !== 2,
                        }
                      )}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
                    />
                  )}
                  <div
                    className={classNames("flex flex-col gap-4", {
                      "text-white": isDark,
                    })}
                  >
                    <Text variant="h3">{heading}</Text>
                    <Text variant="sm">{descriptor}</Text>
                  </div>
                  {!!button?.url && (
                    <Button color={color === "pink" ? "white" : "blackPink"}>
                      {button?.text || "Learn more"}
                    </Button>
                  )}
                </div>
              </AppLink>
            );
          })}
        </div>
      )}
    </Container>
  );
};

CtaGrid.defaultProps = {};

export default CtaGrid;
